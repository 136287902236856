import logo from './mythic-goblin.png';
import './App.css';
import './animation.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
        <p>
          Building portal!
        </p>
      </header>
      <div class="circle"></div>
      <svg>
        <filter id="wavy">
          <feTurbulence x="0" y="0" baseFrequency="0.009" numOctaves="5" speed="2">
            <animate attributeName="baseFrequency" dur="60s" values="0.02; 0.005; 0.02" repeatCount="indefinite"/>
          </feTurbulence>
          <feDisplacementMap in="SourceGraphic" scale="30"></feDisplacementMap>
        </filter>
      </svg>
      <div class="footer">
        Animation by <a href="https://codepen.io/BlackStar1991/pen/xxVOdJb" target="_blank" BlackStar1991>BlackStar1991</a>
      </div>

    </div>
  );
}

export default App;
